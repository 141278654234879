import React from "react";
import Slider from "./Slider";
import MarqueeLComList from "./MarqueeLComList";
import { BackTop } from "antd";

const HomeComponent = () => {
  return (
    <>
      <div className="row m-0">
        <div className="col-md-6">
          <div className="row">
            <div
              className="col-lg-12     "
              style={
                {
                  // height: window.innerHeight / 1.5,
                }
              }
            >
              <div className="img text-end mt-5" style={{}}>
                <Slider />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 d-flex justify-content-start"
          style={
            {
              // height: window.innerHeight / 1.1,
              // minHeight: window.innerHeight / 2,
            }
          }
        >
          <div
            className="api"
            style={
              {
                // height: window.innerHeight / 1.9
              }
            }
          >
            <MarqueeLComList />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
