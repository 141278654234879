import React from "react";
import { useState } from "react";
import LoaderTri from "../../components/Loader/LoaderTri";
import { Image } from "antd";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 text-center ">
          <br />
          {isLoading && <LoaderTri />}
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d488835.6773490089!2d74.14183921456835!3d16.824930800000022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc123b5dab20501%3A0x27b3eda7b5387101!2sVardhaman%20Distributors!5e0!3m2!1sen!2sin!4v1690288630003!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3821.5031700442096!2d74.45415291063449!3d16.701727384006823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc11d3cbaea0bc3%3A0xcc035fb828c566c8!2sAshishmedilink%20llp!5e0!3m2!1sen!2sin!4v1690890591422!5m2!1sen!2sin"
            width="90%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen={false}
            title="ASHISHMEDILINK LLP -  Location"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            onLoad={() => {
              console.log("onLoad");
              setIsLoading(false);
            }}
          ></iframe>

          <br />
          <br />
          <br />
        </div>
        <div className="col-md-6">
          <br />
          {/* <br />
                    <br /> */}

          <div className=" " style={{ width: "90%" }}>
            {/* <h4>ASHISH MEDILINK LLP</h4> */}
            <div className="row  ">
              <div className="col-md-6">
                <h5 className="text-white text-decoration-underline ">
                  ASHISHMEDILINK LLP
                </h5>
                <span
                  style={{
                    fontSize: "11",
                    fontWeight: 500,
                  }}
                >
                  <>
                    R.s.no.50, TPS NO. 2, M.NO.10, H.NO.1058/1, VIKASNAGAR ROAD,
                    ICHALKARANJI-416115,
                  </>
                  <br />
                  <>KOLHAPUR, MAHARASHTRA</>
                  <br />
                  <>PH: (0230) 2438001, 8600026156.</>
                  <br />

                  <>EMAIL : ashishmedilinkllp@gmail.com</>
                  <>
                    {/* <br /> */}
                    {/* GSTIN. 27ABXFA4107A1ZN. */}
                    <br />
                    PAN NO. ABXFA4107A.
                    <br />
                    FOOD LIC : 21522200000952
                    <br />
                    <span
                      style={{ color: "white", textDecoration: "underline" }}
                    >
                      DRUG LICENCE NO:
                    </span>
                    <br />
                    MH -KOL- 20/473965,
                    <br />
                    MH -KOL-20B/473967,
                    <br />
                    MH -KOL- 20C/ 47369,
                    <br />
                    MH -KOL- 20D/473970,
                    <br />
                    MH -KOL- 20F/473971,
                    <br />
                    MH -KOL- 20G/473972.
                    <br />
                  </>
                </span>
              </div>
              <div className="col-md-6">
                <h5 className="text-white text-decoration-underline ">
                  MAHARASHTRA MEDICAL AGENCIES
                </h5>
                <span
                  style={{
                    fontSize: "11",
                    fontWeight: 500,
                  }}
                >
                  CS NO. 7/2A, M NO-7/489,1st&2nd floor, ST stand road ,
                  ISLAMPUR -415409
                  <br />
                  <br /> PH:(02342)224231,8329875701
                  <br />
                  EMAIL : maharashtramedi421@gmail.com
                  <br />
                  GSTIN.: 27ABUFM3353P1ZD
                  <br />
                  <br />
                  <span style={{ color: "white", textDecoration: "underline" }}>
                    DRUG LICENCE NO:
                  </span>
                  <br />
                  MH-SNG-20B/350272,
                  <br />
                  MH-SNG-21B/350274,
                  <br />
                  MH-SNG-20D/350273.
                  <br />
                </span>
              </div>
            </div>

            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
