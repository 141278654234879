import React from "react";
import img2 from "../../Images/logo.png";
import Footer from "../../Footer";
import { useHistory } from "react-router-dom";
import "../../App.css";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import OurVision from "../OurVision/OurVision";
import OurValues from "../OurValues/OurValues";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import HomeComponent from "./components/HomeComponent";
import "./css/home.css";

function Home(props) {
  const pageName =
    props && props.match && props.match.params && props.match.params.pageName;
  // console.log(pageName);
  const history = useHistory();

  const [currentComponent, setCurrentComponent] = useState(pageName);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substr(1);
      setCurrentComponent(hash || "Home");
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderComponent = useCallback(() => {
    // console.log({ currentComponent });
    switch (currentComponent) {
      case "AboutUs":
        return <AboutUs />;
      case "OurVision":
        return <OurVision />;
      case "OurValues":
        return <OurValues />;
      case "ContactUs":
        return <ContactUs />;
      default:
        return <HomeComponent />;
    }
  }, [currentComponent]);

  const handleNavLinkClick = (component) => {
    history.push("#" + component);
    setCurrentComponent(component);
  };

  return (
    <>
      <div className="main">
        <div className="row m-0">
          <div className="col-md-1 col-12">
            <img
              src={img2}
              // height={window.innerWidth < 720 ? "auto" : 95}
              height={95}
              className="mx-lg-3"
              width={92}
              // className="logo"
              // style={{ width: "100%" }}
              alt=""
            />
          </div>
          <div className="col-md-4 col-9">
            <h1 className="pl-lg-3 headerH1 text-center mb-0">
              ASHISHMEDILINK LLP
            </h1>
            <h4 className="headerH4 text-center">
              The Strength of The Retailers
            </h4>
          </div>
          <div className="col-md-7 col-12  d-flex  align-items-center justify-content-end   ">
            <h3>PH: (0230) 2438001, 8600026156</h3>
          </div>
          {/* <div
                        className='col-md-6 d-flex justify-content-evenly align-items-center'>


                        <Link to="/ProductList" className='list '>Product List</Link>
                        <Drop />
                    </div> */}
        </div>
      </div>
      <Navbar expand="lg" expanded style={{ padding: 0 }}>
        <Container
          fluid
          style={{
            backgroundColor: "#b29dac",
            padding: 2,
            minHeight: "74px",
            boxShadow: "rgba(0, 0, 0, 0.15) 2px 5px 1rem 4px",
          }}
        >
          {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 container "
              style={{
                maxHeight: "100px",
                display: window.innerWidth < 1024 ? "flex" : "contents",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
              navbarScroll
            >
              <Nav.Link
                className={
                  (currentComponent === "Home" || currentComponent === "home"
                    ? "activeLink"
                    : "") + " linksPadding "
                }
                style={{ fontSize: 28 }}
                onClick={() => handleNavLinkClick("Home")}
              >
                Home
              </Nav.Link>
              {/* <Nav.Link
                className={
                  (currentComponent === "AboutUs" ? "activeLink" : "") +
                  " mr-3 linksPadding "
                }
                onClick={() => handleNavLinkClick("AboutUs")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className={
                  (currentComponent === "OurVision" ? "activeLink" : "") +
                  " mr-3 linksPadding"
                }
                onClick={() => handleNavLinkClick("OurVision")}
              >
                Our Vision
              </Nav.Link>
              <Nav.Link
                className={
                  (currentComponent === "OurValues" ? "activeLink" : "") +
                  " mr-3 linksPadding"
                }
                onClick={() => handleNavLinkClick("OurValues")}
              >
                Our Values
              </Nav.Link> */}
              <Nav.Link
                className={
                  (currentComponent === "ContactUs" ? "activeLink" : "") +
                  " mr-3 linksPadding"
                }
                style={{ fontSize: 28 }}
                onClick={() => handleNavLinkClick("ContactUs")}
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                className="linksPadding"
                onClick={() => history.push("/ProductList")}
                style={{ fontSize: 28 }}
              >
                Product List
              </Nav.Link>
            </Nav>
            <Nav
              // className="d-flex linksPadding "
              style={{
                maxHeight: "100px",
                position: window.innerWidth > 768 ? "absolute" : false,
                right: 0,
              }}
              navbarScroll
            >
              <NavDropdown
                title="Login As"
                style={{
                  marginRight: "20px",
                }}
                className="linksPadding"
                align={"end"}
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item onClick={() => history.push("/Customer")}>
                  Customer
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => history.push("/Company")}>
                  Company
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        className="middle"
        style={{
          minHeight: window.innerHeight / 1.4,
          padding: 10,
        }}
      >
        {renderComponent()}
      </div>
      <Footer />
    </>
  );
}
export default Home;
