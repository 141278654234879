import { useState } from "react";
import img1 from "../../../Images/img1.jpg";
import img2 from "../../../Images/img2.jpg";
import img3 from "../../../Images/img3.jpg";

// import { Carousel, Image } from 'react-bootstrap';
import { Carousel, Image } from "antd";

function Slider() {
  const [selectedImage, setSelectedImage] = useState(0);
  const [allImages, setAllImages] = useState([img1, img2, img3]);

  // useEffect(() => {
  //     setInterval(() => {
  //         setSelectedImage(selectedImage => selectedImage > 1 ? 0 : selectedImage + 1)
  //     }, 2500);
  // }, [])

  return (
    <>
      <div
        style={{
          // width: window.innerWidth / 3
          marginTop: "1.5rem",
        }}
      >
        <Carousel autoplay autoplaySpeed={2500}>
          {/* <Carousel.Item> */}
          {allImages.map((item, index) => (
            <Image
              src={item}
              key={index}
              width={"100%"}
              height="400px"
              alt={"ImagePlaceholder" + index}
            />
          ))}
          {/* </Carousel.Item> */}
        </Carousel>
        {/* <img
                    style={{
                        minHeight: "290px",
                        maxHeight: "300px",
                    }}
                    height={"auto"}
                    width={"100%"}
                    src={allImages[selectedImage]}
                    alt="" /> */}
      </div>
    </>
  );
}
export default Slider;
