import { useRef, useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import CustomerFooter from "./CustomerFooter";
import HeaderCustomer from "./HeaderCustomer";
import './Customer.css';
import { addDays } from 'date-fns';
import * as XLSX from 'xlsx';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ApiHandler from "../../ApiHandler";
import TransferData from "../../TransferData";
import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import LoaderTri from "../Loader/LoaderTri";


const initialValues = {
    from: dayjs(),
    to: dayjs()
};
export default function BillTracking() {
    const [dates, setDates] = useState(initialValues);

    const [showBillTracking, setShowBillTracking] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [alldatesData, setAlldatesData] = useState([])
    const [openDate, setOpenDate] = useState(false)
    const [viewdata, setViewdata] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [range, setRange] = useState([{
        startdate: new Date(),
        endDate: addDays(new Date(), ''),
        key: 'selection'
    }])

    const [changeDatanameTable, setChangeDatanameTable] = useState([]);

    const refOne = useRef(null);

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
        return () => {
            document.removeEventListener("keydown", hideOnEscape, true)
            document.removeEventListener("click", hideOnClickOutside, true)
        }
    }, []);

    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpenDate(false)
        }
    }


    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpenDate(false)
        }
    }

    // const handleSelects = (date) => {

    //     let filtered = alldatesData.filter((product) => {

    //         let productDate = new Date(product["date"]);
    //         return (
    //             productDate >= date.selection.startDate &&
    //             productDate <= date.selection.endDate
    //         );
    //     });
    //     setStartDate(date.selection.startDate);
    //     setEndDate(date.selection.endDate);
    //     setShowBillTracking(filtered);
    //     console.log(date.selection.startDate)
    //     console.log(date.selection.endDate)
    // };

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
    };
    function toGetData() {
        setIsLoading(true)
        setShowBillTracking([])
        setViewdata(true)
        const localUrl = JSON.parse(localStorage.getItem('LocalURL'))
        const user = JSON.parse(localStorage.getItem('user-info'))
        console.log(user.code)
        ApiHandler.post({
            url: localUrl + "api/OwnApp/GetCustomerBillTracking",
            data: {
                // startdate: moment(selectionRange.startDate).format("YYYYMMDD"),
                // enddate: moment(selectionRange.endDate).format("YYYYMMDD"),
                code: user.code,
                startdate: dayjs(dates.from).format("YYYYMMDD"),
                enddate: dayjs(dates.to).format("YYYYMMDD"),
            },
            useUserUrl: true
        })
            .then(((result) => {
                console.log(result);
                console.log(result.data);
                setShowBillTracking(result.data)
                setAlldatesData(result.data)

            }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                    //   RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }
            }).finally(() => {
                setIsLoading(false)
            })





        //  const localUrl = JSON.parse(localStorage.getItem('LocalURL'))
        // axios.post(localUrl + "api/OwnApp/GetCustomerBillTracking", {

        // },

        // )
        //     .then(((result) => {
        //         console.log(result);
        //         console.log(result.data);
        //         setShowBillTracking(result.data)
        //         setAlldatesData(result.data)
        //     }))
        //     .catch(err => {
        //         console.error(err);
        //         if (err.response && err.response.status && err.response.status === 401) {
        //             console.log(err.response);
        //             //   RefreshTokenHandler();
        //             //  ToastError("Token has expired Please refresh");
        //         }
        //     }

        //     )
    }
    function handleExportDataExcel() {
        console.log(showBillTracking);
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(showBillTracking);
        XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
        XLSX.writeFile(wb, "Reports.xlsx");
    }

    useEffect(() => {
        setChangeDatanameTable(TransferData);
        console.log(changeDatanameTable)
    }, [changeDatanameTable])


    //const dateField = (Input !== "");
    return (<>
        <HeaderCustomer />
        <div style={{ minHeight: window.innerHeight / 1.4 }}>
            <div className="row m-0">
                <div className="col-lg-10 offset-lg-1">
                    <h5 style={{
                        marginTop: "20px",
                        // marginLeft: "20px",
                        fontFamily: "sans-serif",
                        // fontSize: "17px",
                        // padding: "10px"

                    }}>Select details to  Track Your Bill</h5>

                    <div className="row">
                        <div className="col-lg-3  ">
                            <DatePicker.RangePicker
                                size="middle"
                                className=" mb-2 mr-2"
                                value={[dates.from, dates.to]}
                                onChange={(e) => {
                                    console.log(e);

                                    if (e) {
                                        setDates({
                                            from: e[0],
                                            to: e[1],
                                        });
                                    } else {
                                        setDates({ from: null, to: null });
                                    }
                                }}
                            />
                        </div>
                        <div className="col-lg-2  ">
                            <Button
                                type="button"
                                className="btn  mb-2 btn-primary btn-sm"
                                onClick={toGetData}
                            >View</Button>

                        </div>
                    </div>
                    {/* <div className="enterDateInput">
                        <form >
                            <input
                                value={`${moment(selectionRange.startDate).format("DD-MM-YYYY")} to ${moment(selectionRange.endDate).format("DD-MM-YYYY")}`}
                                //value={`${format(range[0].startdate, 'MM-dd-yyyy')}  to  ${format(range[0].endDate, 'MM-dd-yyyy')} `}
                                readOnly
                                onClick={() => setOpenDate(true)}
                                style={{
                                    width: "300px",
                                    paddingLeft: '30px'

                                }}
                                required

                            />

                            <button

                                type="button"
                                onClick={toGetData}

                            >View</button>

                        </form>
                    </div>
                    <div className="dateRangePicker" ref={refOne}>



                        {

                            openDate &&
                            <DateRange
                                onChange={handleSelects}
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                ranges={[selectionRange]}
                                months={2}
                                direction="horizontal"
                                showMonthAndYearPickers={false}
                                className="DaterangePicker"

                            />


                        }
                    </div> */}


                    {
                        viewdata &&
                        <div className="   ">

                            <div className="billTarckData">
                                <h6>Bill Track</h6>
                                {showBillTracking.length > 0 &&
                                    <button onClick={handleExportDataExcel}>
                                        Download
                                    </button>}

                                <div style={{
                                    width: "100%",
                                    overflowX: "scroll",
                                }}>
                                    {


                                        Array.isArray(showBillTracking) && showBillTracking.length > 0 &&
                                        <Table striped >
                                            <thead className="threAd">
                                                <tr className="threAd_tr">



                                                    <th>{TransferData.orderDate}</th>
                                                    <th>{TransferData.billNo}</th>
                                                    <th style={{ textAlign: "right" }}>{TransferData.amount}</th>
                                                    <th>{TransferData.status}</th>

                                                </tr>
                                            </thead>

                                            {


                                                showBillTracking.map((elm, index) => {
                                                    return <tbody className="tboDY" key={index} >
                                                        <tr >

                                                            <td >{elm.date}</td>
                                                            <td>{elm.billNo}</td>
                                                            <td style={{ textAlign: "right" }}>{elm.amount}</td>
                                                            <td>{elm.status}</td>

                                                        </tr>
                                                    </tbody>

                                                })

                                            }

                                        </Table >
                                    }
                                </div>

                                {isLoading &&
                                    <div className="d-flex justify-content-center" >
                                        <LoaderTri />

                                    </div>

                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

        <CustomerFooter />
    </>)
}