import React from "react";
import "./App.css";
import iconPayStore from "./assets/images/badge-en.svg";
function Footer() {
  return (
    <>
      <footer
        className="footer pt-5 pb-5"
        style={{
          bottom: 0,
          // position: "fixed",
          width: "100%",

          boxShadow: "rgba(0, 0, 0, 0.15) 0px -7px 1rem 4px",
        }}
      >
        <div className="row m-0" style={{}}>
          <div
            className="col-md-5 pl-5 offset-md-1 "
            style={{
              borderLeft: "1px solid black",
            }}
          >
            <h4 style={{ textDecoration: "underline" }}>ASHISHMEDILINK LLP </h4>
            <h5>GSTIN. 27ABXFA4107A1ZN. </h5>
            <h5 className="text-decoration-underline ">BANK DETAIL</h5>
            <h6>BANK NAME: ICICI BANK </h6>
            <h6>AC.NO. : 635705056118 </h6>
            <h6>Ifsc code : ICIC0006357</h6>
            <h6>Branch : Ichalkaranji</h6>
            <h4 className="mt-3">Contact:-</h4>
            <h6>Ph: + 91 230 2438001,</h6>
            <h6>&nbsp; &nbsp; &nbsp; + 91 860 0026156.</h6>

            <a
              href="https://play.google.com/store/apps/details/ASHISH_MEDILINK_LLP?id=in.relysoft.ashishmedilinkllp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={iconPayStore}
                height={60}
                width={"auto"}
                alt="Play Store"
              />
            </a>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className=" col-md-4  "></div>
              <div className=" col-md-8">
                <div className="row">
                  <div
                    className=" col-md-12  "
                    style={{
                      borderLeft: "1px solid black",
                    }}
                  >
                    <h4 style={{ textDecoration: "underline" }}>
                      MAHARASHTRA MEDICAL AGENCIES
                    </h4>
                    <h5>GSTIN.: 27ABUFM3353P1ZD</h5>
                    <h5 className="text-decoration-underline ">BANK DETAIL</h5>
                    <h6>BANK NAME: ICICI BANK</h6>

                    <h6> AC NO. - 635705056465</h6>

                    <h6> IFSC CODE : ICIC0006357</h6>

                    <h6> BRANCH : ICHALKARANJI</h6>

                    <h4 className="mt-3">Contact:-</h4>
                    <h6>Ph: (02342)224231,</h6>
                    <h6>&nbsp; &nbsp; &nbsp; 8329875701.</h6>

                    <a
                      href="https://play.google.com/store/apps/details?id=in.relysoft.maharashtramedicalagencies"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={iconPayStore}
                        height={60}
                        width={"auto"}
                        alt="Play Store"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='inside_f p-1 pt-2'>
                    <p>C.S.NO 11142, W.NO 10, H.NO. 144, Ground Floor,<br /> Radhakrishna theater, M.NO 102000592,Ichalkaranji<br /> 416115, Maharashtra<br />
                        GSTIN:27ADRFS2459P1Z1</p>
                    <div className='inside_ff'>
                        <ul>
                            <li>bank details</li>
                            <li>Current A/C. No.</li>
                            <li>IFSC Code</li>
                        </ul>


                    </div>
                    <div className='inside_fff'>
                        <ul>
                            <li>Cosmos Bank, Ichalkaranji</li>
                            <li>114100103162</li>
                            <li>COSB0000114</li>
                        </ul>


                    </div>
                </div> */}
      </footer>
    </>
  );
}
export default Footer;
